import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "设置",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_cell, {
    title: "修改密码",
    "is-link": "",
    to: "updatepassword"
  }), _createVNode(_component_van_cell, {
    title: "关于",
    value: "当前版本1.0"
  }), _createVNode(_component_van_cell, {
    title: "客户电话",
    value: "4001-188-188"
  }), _createElementVNode("div", {
    class: "out",
    onClick: _cache[0] || (_cache[0] = $event => $options.out(1)),
    style: {
      "margin-top": "50px"
    }
  }, " 切换账号 "), _createElementVNode("div", {
    class: "out",
    onClick: _cache[1] || (_cache[1] = $event => $options.out(2))
  }, " 注销账号 ")], 64);
}
import { Dialog } from 'vant';
export default {
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    out(index) {
      if (index == 1) {
        if (localStorage.getItem('dzmmtoken')) {
          localStorage.removeItem('dzmmtoken');
        }
        this.$router.replace({
          path: '/login'
        });
      } else {
        Dialog.confirm({
          title: '提示',
          message: '是否确认注销账户'
        }).then(() => {
          if (localStorage.getItem('dzmmtoken')) {
            localStorage.removeItem('dzmmtoken');
          }
          this.$router.replace({
            path: '/login'
          });
        }).catch(() => {
          // on cancel
        });
      }
    }
  }
};